<template>
  <Modal :options="{width: '30vw'}" @close="$emit('close')">
    <div class="title">Estatus solicitud {{ solicitud.id }}</div>
    <div class="body">
      <div class="row form-group">
        <div class="col-sm-12">Registra el estatus de la solicitud en la etapa/área "<strong>{{ area }}</strong>" monte</div>
      </div>

      <div class="row form-group">
        <label for="estatus_solicitud" class="col-form-label col-sm-3">Estatus de la solicitud</label>
        <div class="col-sm-9">
          <select v-model="estatus_solictud" name="estatus_solicitud" id="estatus_solicitud" class="form-control">
            <option value="na">Seleccione el estatus de la solicitud</option>
            <option value="1">Aprobado</option>
            <option value="0">Rechazado</option>
          </select>
        </div>
      </div>

      <div v-if="estatus_solictud == '0'" class="row form-group">
        <label for="razon_rechazo" class="col-form-label col-sm-3">Razón de rechazo</label>
        <div class="col-sm-9">
          <textarea v-model="estatus_razon" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="row">
        <div class="col-sm-12 text-right">
          <button class="btn btn-primary mr-2" @click="guardar_estatus">Aceptar</button>
          <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'

import apiSolicitud from '@/apps/solicitudes/api/solicitudes'

export default {
  components: {
    Modal
  }
  ,props: {
    solicitud: {
      type: Object,
      default() {
        return {
          id: null
        }
      }
    }
    ,area: {
      type: String,
      default: ''
    }
  }
  ,data() {
    return {
      estatus_solictud: 'na'
      ,estatus_razon: null
    }
  }
  ,methods: {
    async guardar_estatus() {
      try {
        if (this.estatus_solictud == 'na')
          return this.$helper.showMessage('Error','Debes seleccionar el estatus de la solicitud','error','alert');

        if (this.estatus_solictud == 0 && !this.estatus_razon)
          return this.$helper.showMessage('Error','Si el estatus es rechazado debes especificar la razón','error','alert');

        let payload = {
          alianza: 'montepiedad'
          ,propiedad: 'estatus_solicitud_'+this.area
          ,valor: JSON.stringify({
            estatus: this.estatus_solictud,
            razon: this.estatus_razon,
          }),
          vista: 'estatus'
        }

        let res = (await apiSolicitud.crear_propiedad(this.solicitud.id, payload)).data;
        this.$log.info('res', res);
        this.$emit('update');
        this.$emit('close');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}

</script>