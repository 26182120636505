<template>
  <div>
    <div class="row mb-3">
      <div class="col-sm-12 text-right">
        <button class="btn btn-primary mr-2" @click="modal_subir_documento=true">Cargar documento</button>
        <button class="btn btn-info" @click="modal_agregar_comentario=true">Agregar comentario</button>
      </div>
    </div>
    <table class="estatus_areas">
      <thead>
        <tr>
          <th>Área</th>
          <th>Creada / Actualizada</th>
          <th>Estatus</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Callback</td>
          <td v-html="fechas('callback')"></td>
          <td>
            <span :class="capsula_estado('callback')" title="Estatus área"><i :class="estatus_etapa('callback')"></i></span>
            <span :class="comentarios_estatus('callback')" title="Documentos" @click="abrir_modal_observaciones('callback')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('callback')" title="Documentos" @click="abrir_modal_documentos('callback')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('callback')" title="Estatus documento" @click="abrir_modal_estatus('callback')"><i :class="solicitud_estatus_icon('callback')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Opinión de Valor</td>
          <td v-html="fechas('opinion')"></td>
          <td>
            <span :class="capsula_estado('opinion')" title="Estatus área"><i :class="estatus_etapa('opinion')"></i></span>
            <span :class="comentarios_estatus('opinion')" title="Observaciones" @click="abrir_modal_observaciones('opinion')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('opinion')" title="Documentos" @click="abrir_modal_documentos('opinion')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('opinion')" title="Estatus documento" @click="abrir_modal_estatus('opinion')"><i :class="solicitud_estatus_icon('opinion')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Preautorización</td>
          <td v-html="fechas('citas')"></td>
          <td>
            <span :class="capsula_estado('citas')" title="Estatus área"><i :class="estatus_etapa('citas')"></i></span>
            <span :class="comentarios_estatus('citas')" title="Observaciones" @click="abrir_modal_observaciones('citas')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('citas')" title="Documentos" @click="abrir_modal_documentos('citas')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('citas')" title="Estatus documento" @click="abrir_modal_estatus('citas')"><i :class="solicitud_estatus_icon('citas')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Documentación</td>
          <td v-html="fechas('entrevistas')"></td>
          <td>
            <span :class="capsula_estado('entrevistas')" title="Estatus área"><i :class="estatus_etapa('entrevistas')"></i></span>
            <span :class="comentarios_estatus('entrevistas')" title="Observaciones" @click="abrir_modal_observaciones('entrevistas')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('entrevistas')" title="Documentos" @click="abrir_modal_documentos('entrevistas')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('entrevistas')" title="Estatus documento" @click="abrir_modal_estatus('entrevistas')"><i :class="solicitud_estatus_icon('entrevistas')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Autorización</td>
          <td v-html="fechas('comite')"></td>
          <td>
            <span :class="capsula_estado('comite')" title="Estatus área"><i :class="estatus_etapa('comite')"></i></span>
            <span :class="comentarios_estatus('comite')" title="Observaciones" @click="abrir_modal_observaciones('comite')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('comite')" title="Documentos" @click="abrir_modal_documentos('comite')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('comite')" title="Estatus documento" @click="abrir_modal_estatus('comite')"><i :class="solicitud_estatus_icon('comite')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Ejercido</td>
          <td v-html="fechas('ejercido')"></td>
          <td>
            <span :class="capsula_estado('ejercido')" title="Estatus área"><i :class="estatus_etapa('ejercido')"></i></span>
            <span :class="comentarios_estatus('ejercido')" title="Observaciones" @click="abrir_modal_observaciones('ejercido')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('ejercido')" title="Documentos" @click="abrir_modal_documentos('ejercido')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('ejercido')" title="Estatus documento" @click="abrir_modal_estatus('ejercido')"><i :class="solicitud_estatus_icon('ejercido')"></i></span>
          </td>
        </tr>
        <tr>
          <td>Desarrollo Empresarial</td>
          <td v-html="fechas('desarrollo')"></td>
          <td>
            <span :class="capsula_estado('desarrollo')" title="Estatus área"><i :class="estatus_etapa('desarrollo')"></i></span>
            <span :class="comentarios_estatus('desarrollo')" title="Observaciones" @click="abrir_modal_observaciones('desarrollo')"><i class="fa-solid fa-comment"></i></span>
            <span :class="documentos_estatus('desarrollo')" title="Documentos" @click="abrir_modal_documentos('desarrollo')"><i class="fa-solid fa-photo-film"></i></span>
            <span v-if="$auth.can('solicitudes','alianza_soc')" :class="solicitud_estatus('desarrollo')" title="Estatus documento" @click="abrir_modal_estatus('desarrollo')"><i :class="solicitud_estatus_icon('desarrollo')"></i></span>
          </td>
        </tr>
      </tbody>
    </table>

    <Observaciones v-if="modal_observaciones" :solicitud="solicitud" :area="modal_observaciones_area" @close="cerrar_modal_observaciones" />
    <Documentos v-if="modal_documentos" :solicitud="solicitud" :area="modal_documentos_area" @close="cerrar_modal_documentos" />
    <MonteEstatusSolicitud v-if="$auth.can('solicitudes','alianza_montepiedad') && modal_estatus_solicitud" :solicitud="solicitud" :area="modal_monte_estatus_area" @close="cerrar_modal_monte_estatus" @update="$emit('update')" />
    <SOCEstatusSolicitud v-if="$auth.can('solicitudes','alianza_soc') && modal_estatus_solicitud" :solicitud="solicitud" :area="modal_monte_estatus_area" @close="cerrar_modal_monte_estatus" @update="$emit('update')" />
    <SubirDocumento v-if="modal_subir_documento" :solicitud="solicitud" @update="actualizar_cerrar_subir_documento" @close="modal_subir_documento=false" />
    <AgregarComentario v-if="modal_agregar_comentario" :solicitud="solicitud" @update="actualizar_cerrar_comentarios" @close="modal_agregar_comentario=false" />
  </div>
</template>

<script>
import Observaciones from './EstatusArea/Observaciones'
import Documentos from './EstatusArea/Documentos'
import MonteEstatusSolicitud from '@/apps/solicitudes/Alianzas/MontePiedad/EstatusSolicitud';
import SOCEstatusSolicitud from '@/apps/solicitudes/Alianzas/SOC/EstatusSolicitud';
import SubirDocumento from './EstatusArea/SubirDocumento'
import AgregarComentario from './EstatusArea/Comentario'

import api from '@/apps/solicitudes/api/servicios'

export default {
  components: {
    Observaciones, Documentos, MonteEstatusSolicitud, SubirDocumento, AgregarComentario, SOCEstatusSolicitud
  },
  props: {
    solicitud: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      modal_observaciones: false
      ,modal_observaciones_area: null
      ,modal_documentos: false
      ,modal_documentos_area: null
      ,modal_estatus_solicitud: false
      ,modal_monte_estatus_area: null
      ,modal_subir_documento: false
      ,modal_agregar_comentario: false
    }
  },
  methods: {
    fechas(tipo) {
      let fechas = "";
      if (this.solicitud["created_" + tipo])
        fechas += '<span class="status_time status_time_created">'+this.$moment(this.solicitud["created_"+tipo]).format("YYYY-MM-DD HH:mm")+"</span>";

      if (this.solicitud["updated_" + tipo])
        fechas += '<span class="status_time status_time_updated">'+this.$moment(this.solicitud["updated_"+tipo]).format("YYYY-MM-DD HH:mm")+"</span>";

      return fechas;
    },
    obtener_propiedad(propiedad) {
      let alianza_propiedad = {valor: null};
      let tipo_alianza = null;

      if (this.$auth.can('solicitudes', 'alianza_montepiedad'))
        tipo_alianza = 'montepiedad';

      if (this.$auth.can('solicitudes', 'alianza_soc'))
        tipo_alianza = 'soc';

      if (this.$auth.can('solicitudes', 'alianza_frd'))
        tipo_alianza = 'frd';

      if (this.solicitud && this.solicitud.alianzas_propiedades && this.solicitud.alianzas_propiedades.length > 0) {
        for(let i=0; i<this.solicitud.alianzas_propiedades.length; i++) {
          if (this.solicitud.alianzas_propiedades[i].alianza == tipo_alianza && this.solicitud.alianzas_propiedades[i].propiedad == propiedad)
            alianza_propiedad = this.solicitud.alianzas_propiedades[i];
        }
      }

      if (alianza_propiedad && alianza_propiedad.valor && typeof alianza_propiedad.valor == 'string')
        alianza_propiedad.valor = JSON.parse(alianza_propiedad.valor);

      return alianza_propiedad.valor;
    },
    capsula_estado(area) {
        let clase = 'capsule';

        switch(this.solicitud['procede_'+area]) {
            case 1:
                clase += ' capsule_done';
                break;
            case 0:
                clase += ' capsule_fail';
                break;
        }        

        return clase;
    },
    estatus_etapa(area) {
        let icon = 'fa-solid';

        switch(this.solicitud['procede_'+area]) {
            case 1:
                icon += ' fa-check';
                break;
            case 0:
                icon += ' fa-xmark';
                break;
            default:
                icon += ' fa-minus';
        }

        return icon;
    },
    comentarios_estatus(area) {
        let icon = 'capsule';

        if (this.solicitud['obs_'+area] > 0)
            icon += ' capsule_comment';

        return icon;
    },
    documentos_estatus(area) {
        let icon = 'capsule';

        if (this.solicitud['docs_'+area])
            icon += ' capsule_dock';

        return icon;
    },
    solicitud_estatus(area) {
      let icon = 'capsule';
      
      let estatus_solicitud = this.obtener_propiedad('estatus_solicitud_'+area);
      if (estatus_solicitud !== null)
        if (estatus_solicitud.estatus == 1)
          icon += ' capsule_status_valid';
        else
          icon += ' capsule_status_invalid';
      else if (this.solicitud['procede_'+area])
        icon +=' capsule_btn';

      return icon;
    },
    solicitud_estatus_icon(area) {
      let icon = 'fa-solid';

      let estatus_solicitud = this.obtener_propiedad('estatus_solicitud_'+area);

      if (estatus_solicitud == null)
        icon += ' fa-circle-question';
      else  if (estatus_solicitud.estatus == 1)
        icon +=' fa-check';
      else if (estatus_solicitud.estatus == 0)
        icon +=' fa-xmark';

      return icon;
    }
    ,abrir_modal_observaciones(area) {
      if (this.solicitud['obs_'+area] > 0) {
        this.modal_observaciones_area = area;
        this.modal_observaciones = true;
      }
    }
    ,cerrar_modal_observaciones() {
      this.modal_observaciones = false;
    }
    ,abrir_modal_documentos(area) {
      if (this.solicitud['docs_'+area]) {
        this.modal_documentos = true;
        this.modal_documentos_area = area;
      }
    }
    ,cerrar_modal_documentos() {
      this.modal_documentos = false;
    }
    ,abrir_modal_estatus(area) {
      let estatus_solicitud = this.obtener_propiedad('estatus_solicitud_'+area);

      switch(this.solicitud['procede_'+area]) {
        case 1:
          if (estatus_solicitud == null) {
            this.modal_monte_estatus_area = area;
            this.modal_estatus_solicitud = true;
          }
          break;
      }
    }
    ,cerrar_modal_monte_estatus() {
      this.modal_estatus_solicitud = false;
    }
    ,actualizar_cerrar_subir_documento() {
      this.modal_subir_documento = false;
      this.$emit('update');
    }
    ,actualizar_cerrar_comentarios() {
      this.modal_agregar_comentario = false;
      this.$emit('update');
    }
  },
};
</script>

<style lang="scss">
.estatus_areas {
  width: 100%;

  thead {
    tr {
      background-color: rgb(94, 94, 94);

      th {
        padding: 5px 10px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: solid 1px rgb(231, 231, 231);

      td {
        span.status_time {
          background-color: rgb(241, 241, 241);
          padding: 3px 5px;
          margin: 0px 5px;
          border-radius: 5px;
          display: inline-block;
        }

        span.status_time_created {
          background-color: rgb(145, 226, 159);
        }

        span.status_time_updated {
          background-color: rgb(245, 220, 138);
        }

        span.capsule {
          width: 37px;
        //   font-size: 1.3em;
          color: rgb(216, 216, 216);
          padding: 5px 8px;
          margin: 5px 8px;
          border: solid 1px rgb(216, 216, 216);
          border-radius: 3px;
          display: inline-block;

          i.fa-check, svg.fa-check {
            margin-left: 4px;
          }

          i.fa-minus, svg.fa-minus {
            margin-left: 4px;
          }
          
          i.fa-comment, svg.fa-comment {
            margin-left: 3px;
          }

          i.fa-xmark, svg.fa-xmark {
            margin-left: 5px;
          }

          i.fa-circle-question, svg.fa-circle-question {
            margin-left: 3px;
          }
        }

        span.capsule_btn {
          cursor: pointer;
        }

        span.capsule_done {
          background-color: #32b13c;
          border-color: gray;
          color: #fff;
        }

        span.capsule_fail {
          background-color: #b13232;
          border-color: gray;
          color: #fff;
        }

        span.capsule_comment {
            background-color: #32a2b1;
            color: #fff;
            border-color: gray;
            cursor: pointer;
        }

        span.capsule_dock {
          background-color: #3276b1;
          color: #fff;
          border-color: gray;
          cursor: pointer;
        }

        span.capsule_status_valid {
          background-color: #32b13c;
          color: #fff;
          border-color: gray;
          cursor: default;
        }

        span.capsule_status_invalid {
          background-color: #b13232;
          color: #fff;
          border-color: gray;
          cursor: default;
        }
      }
    }
  }
}
</style>
